<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="orders layout__grow">
    <ui-section>
      <ui-nav-bar slot="header" class="orders__nav-bar" :transparent="true">
        <ui-nav-bar-item class="orders__selectall">&nbsp;</ui-nav-bar-item>
        <ui-nav-bar-item class="orders__refresh">
          <ui-icon
            type="refresh"
            size="16"
            @click="handleRefresh"
          />
        </ui-nav-bar-item>
        <div class="orders__search-wrapper">
          <uuid-search v-if="context !== 'search'" />
          <tooltip
            :disabled="printSelectedAvailable || !documentsTypes.length"
            content="In this sales channel this option is not available"
            placement="top-start"
            max-width="180"
          >
            <ui-nav-bar-item class="orders__print-popup">
              <ui-button
                v-if="showPrintOption"
                :disabled="!printSelectedAvailable || !checkedOrders.length"
                class="orders__print-selected"
                @click="handlePrint"
              >
                <span>Print selected</span>
                <ui-icon
                  class="orders__print-icon"
                  size="4"
                  type="triangle"
                />
              </ui-button>
              <orders-print
                v-if="printSelected && !!checkedOrders.length"
                :shop-id="shopId"
                :order-id="checkedOrders[0]"
                :documents="documentsTypes"
                @cancel="handlePrint"
              />
            </ui-nav-bar-item>
          </tooltip>
          <ui-nav-bar-item class="orders__print-popup">
            <ui-button
              v-if="showPrintOption"
              :disabled="!checkedOrders.length"
              @click="handlePrintAll"
            >
              Print all on one page
            </ui-button>
            <orders-print
              v-if="printAllSelected && !!checkedOrders.length"
              :shop-id="shopId"
              :order-id="checkedOrders[0]"
              :documents="documentsTypes"
              auto-print
              @cancel="handlePrintAll"
            />
          </ui-nav-bar-item>
        </div>
        <span class="orders__checkboxes">
          <ui-nav-bar-item v-if="showPremiumOnlyOption">
            <ui-checkbox-input
              value="1"
              name="premiumOnly"
              class="orders__checkbox"
              @click="handlePremiumOnlyClick"
            >
              <span>VIP only</span>
            </ui-checkbox-input>
          </ui-nav-bar-item>
          <ui-nav-bar-item v-if="showIncompleteOption">
            <ui-checkbox-input
              value="incomplete"
              name="incompleteOnly"
              class="orders__checkbox"
              @click="handleIncompleteClick"
            >
              <span>Incomplete only</span>
            </ui-checkbox-input>
          </ui-nav-bar-item>
        </span>
        <ui-nav-bar-item>
          <Select class="orders__filter-box" :value="filter.sortBy" @on-change="handleSortChange">
            <Option value="-createdAt">Newest first</Option>
            <Option value="+createdAt">Oldest first</Option>
          </Select>
        </ui-nav-bar-item>
        <ui-nav-bar-item v-if="total > limit" class="orders__pagination">
          <ui-pagination
            :limit="limit"
            :offset="offset"
            :total-item-count="total"
            @page-change="handlePaginationChange"
          />
        </ui-nav-bar-item>
      </ui-nav-bar>

      <slot name="tabs" />

      <orders-layout
        ref="resizeLayoutWrapper"
        :with-details="withDetails"
        :is-loading="isLoading"
      >
        <ui-spinner-container :is-loading="isLoading" class="layout__grow">
          <template v-if="!isLoading">
            <orders-list
              v-if="orders.length"
              :orders="orders"
              :shop-id="shopId"
              :url-query="{ search, context }"
              :checked-orders="checkedOrders"
              :context="context"
              class="orders__list-items"
              @checked="handleChecked"
            />
            <template v-else>
              <no-accepted-orders-message v-if="context === 'accepted'" />
              <no-pending-orders-message v-if="context === 'awaiting'" />
              <no-orders-found-message v-if="context === 'search'" />
            </template>
          </template>
        </ui-spinner-container>

        <template #details>
          <router-view />
        </template>
      </orders-layout>
    </ui-section>

    <div ref="resizeLayoutExtraContent" class="orders__remove-shop-form">
      <remove-shop-form v-if="shopId" :shop-id="shopId" />
    </div>
  </div>
</template>

<script>
import {
  UiButton,
  UiSpinnerContainer,
  UiPagination,
  UiIcon,
  UiSection,
  UiNavBar,
  UiNavBarItem,
  UiCheckboxInput,
} from '@ui';
import {
  Select,
  Option,
  Tooltip,
} from 'iview';

import { tabsParamsMapping } from '@/const';

import OrdersLayout from '@/modules/Orders/components/OrdersLayout.vue';
import OrdersList from '@/modules/Orders/components/OrdersList.vue';
import OrdersPrint from '@/modules/Orders/components/OrdersPrint.vue';

import NoAcceptedOrdersMessage from '@/modules/Orders/components/Messages/NoAcceptedOrders.vue';
import NoPendingOrdersMessage from '@/modules/Orders/components/Messages/NoPendingOrders.vue';
import NoOrdersFoundMessage from '@/modules/Orders/components/Messages/NoOrdersFound.vue';
import UuidSearch from '@/modules/Orders/components/UUIDSearch.vue';

import RemoveShopForm from '@/modules/Shops/components/ShopsRemoveShopForm.vue';

export default {
  name: 'OrdersOrdersView',
  components: {
    UiButton,
    UiSpinnerContainer,
    UiIcon,
    UiPagination,
    UiSection,
    UiNavBar,
    UiNavBarItem,
    UiCheckboxInput,
    UuidSearch,
    OrdersList,
    OrdersLayout,
    OrdersPrint,
    NoAcceptedOrdersMessage,
    NoPendingOrdersMessage,
    NoOrdersFoundMessage,
    // eslint-disable-next-line vue/no-reserved-component-names
    Select,
    // eslint-disable-next-line vue/no-reserved-component-names
    Option,
    Tooltip,
    RemoveShopForm,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },
    context: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    filter: {
      tab: 'awaiting',
      sortBy: '-createdAt',
      orderId: '',
    },
    checkedOrders: [],
    documentsTypes: [],
    printSelected: false,
    printAllSelected: false,
    awaitingTrackingNumber: false,
    premiumOnly: false,
    oldShopId: null,
  }),

  computed: {
    orders() {
      if (!this.isPremiumOnly) {
        return this.$store.state.orders.list;
      }

      return this.$store.state.orders.list.filter((
        { shippingInformation: { deliveryInfo: { isPremium = false } } },
      ) => isPremium);
    },

    isPremiumOnly() {
      return this.premiumOnly && this.showPremiumOnlyOption;
    },

    isLoading() {
      return this.$store.state.orders.isLoading;
    },

    limit() {
      return this.$store.state.orders.pagination.limit;
    },

    offset() {
      return this.$store.state.orders.pagination.offset;
    },

    total() {
      return this.$store.state.orders.pagination.total;
    },

    pendingOrdersCount() {
      return this.$store.getters['statistics/countByShop'](this.oldShopId).pending;
    },

    incompleteOrdersCount() {
      return this.$store.getters['statistics/countByShop'](this.oldShopId).incomplete + this.$store.getters['statistics/countByShop'](this.oldShopId).rmas;
    },

    showPrintOption() {
      return this.context !== 'awaiting';
    },

    printSelectedAvailable() {
      return this.$store.getters['orders/order/isPrintSelectedAvailable'];
    },

    showPremiumOnlyOption() {
      return ['accepted', 'awaiting'].includes(this.context);
    },

    showIncompleteOption() {
      return this.context === 'accepted';
    },

    withDetails() {
      return this
        .$route
        .matched
        .some((route) => ['order', 'transfer', 'order-rma-request'].indexOf(route.name) !== -1);
    },
  },

  watch: {
    shopId: {
      handler() {
        const currentShopOldId = this.$store.getters['shops/byId'](this.shopId).oldId;
        this.oldShopId = currentShopOldId;
        this.fetch();
      },
    },

    context() {
      this.resetCheckedOrders();
      this.awaitingTrackingNumber = false;

      this.fetch({
        offset: 0,
      });
    },

    search(newValue, oldValue) {
      // Initial search: oldValue is undefined and will
      // change context so will be handled by watch context()

      // Removing search: newValue is undefined and will
      // change context so will be handled by watch context()

      // Updating search: newValue and oldValue are not undefined
      // so we need to trigger fetch() for that case here
      if (typeof newValue !== 'undefined' && typeof oldValue !== 'undefined') {
        this.fetch();
      }
    },

    pendingOrdersCount(next, before) {
      if (before === 0) {
        if (next > 0 && this.context === 'awaiting') {
          this.fetch({
            offset: 0,
          });
        }
      }
    },

    incompleteOrdersCount(next, before) {
      if (before === 0) {
        if (next > 0 && this.context === 'accepted') {
          this.fetch({
            offset: 0,
          });
        }
      }
    },
  },
  mounted() {
    const currentShopOldId = this.$store.getters['shops/byId'](this.shopId).oldId;
    this.oldShopId = currentShopOldId;

    if (this.search || this.context || this.$route.query.query) {
      this.fetch();
    }
  },

  methods: {
    fetch(params) {
      if (this.oldShopId) {
        if (this.context === 'search') {
          if (this.$route.query.query) {
            this.$store.dispatch('orders/searchUUID', {
              shopId: this.oldShopId,
              params: {
                query: this.$route.query.query,
                sort: this.filter.sortBy,
                limit: this.limit,
                status: [!this.$route.query.status ? 'pending' : this.$route.query.status],
                offset: 0,
              },
            });
          } else {
            this.$store.dispatch('orders/search', {
              shopId: this.oldShopId,
              params: {
                query: this.search,
                sort: this.filter.sortBy,
                limit: this.limit,
                offset: 0,
              },
            });
          }
        } else {
          this.documentsTypes = [];
          this.$store.dispatch('orders/fetchForShop', {
            shopId: this.oldShopId,
            params: {
              ...tabsParamsMapping[this.context],
              sort: this.filter.sortBy,
              limit: this.limit,
              offset: this.offset,
              awaitingTrackingNumber: this.awaitingTrackingNumber || undefined,
              ...params,
            },
          });
          this.$store.dispatch('orders/fetchItemMappingsForShop', this.oldShopId);
        }
      }
    },

    handlePaginationChange(data) {
      this.resetCheckedOrders();
      this.fetch(data);
    },

    handleSortChange(sortBy) {
      this.filter.sortBy = sortBy;
      this.resetCheckedOrders();
      this.fetch({
        offset: 0,
      });
    },

    handleChecked(value) {
      this.resetCheckedOrders();
      // TODO refactor to support checkbox input rather than radio input
      this.$store.dispatch('orders/order/fetchOrder', {
        shopId: this.$props.shopId,
        orderId: value,
      })
        .then((result) => {
          this.documentsTypes = result.data.availabilityOptions.shipping.documentsTypes;
        });

      this.checkedOrders.push(value);
    },

    handleIncompleteClick(value, checked) {
      this.resetCheckedOrders();
      this.awaitingTrackingNumber = checked;
      this.fetch({
        offset: 0,
      });
    },

    handlePremiumOnlyClick(value, checked) {
      this.resetCheckedOrders();
      this.premiumOnly = checked;
    },

    handlePrint() {
      this.printAllSelected = false;
      this.printSelected = !this.printSelected;
    },

    handlePrintAll() {
      this.printSelected = false;
      this.printAllSelected = !this.printAllSelected;
    },

    handleRefresh() {
      this.resetCheckedOrders();
      this.fetch();
    },

    resetCheckedOrders() {
      this.printSelected = false;
      this.printAllSelected = false;
      this.checkedOrders = [];
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/mixins/breakpoints.scss';

  .orders {
    &__nav-bar {
      line-height: normal;
      padding: 13px 0;
      min-height: 72px;
    }

    &__search-wrapper {
      padding-left: 16px;
      gap: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & button {
        margin: 0;
      }
    }

    &__selectall {
      padding-left: 10px;
      width: 30px;
    }

    &__refresh {
      padding-left: 16px;
      padding-right: 16px;
      cursor: pointer;
    }

    &__print {
      &-popup {
        position: relative;
        padding-left: 0;
      }
    }

    &__print-selected {
      position: relative;
      padding-right: 35px;
    }

    &__print-icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &__checkboxes {
      margin-left: auto;
      padding-right: 16px;
      color: #666666;
      margin-top: 5px;

      &-checkbox {
        margin-right: 10px;
      }

      & div {
        display: inline-block;
      }
    }

    &__checkbox {
      margin: 10px;
      height: 12px;
    }

    &__filter-box {
      width: 150px;

      .ivu-select-selection {
        height: 29px;
        border-radius: 6px;

        .ivu-select-selected-value {
          height: 25px;
          line-height: 27px;
          font-size: inherit;
        }
      }
    }

    &__tabs {
      &-search {
        padding-left: 5px;
      }
    }

    &__rma {
      cursor: pointer;
    }

    &__remove-shop-form {
      padding: 10px 0;
    }

    @include for-tablet-portrait-down {
      &__print-selected,
      &__print-popup,
      &__selectall,
      &__incomplete,
      &__legend {
        display: none;
      }

      &__nav-bar {
        min-height: 0;
      }

      &__refresh {
        padding-left: 8px;
        padding-right: 8px;
      }

      &__filter-box {
        width: 120px;
      }

      &__pagination {
        margin-left: auto;
      }

      &__tabs {
        padding: 0 16px;
        border-bottom: 1px solid #E5E6E8;

        &:before {
            display: none;
        }
      }
    }
  }
</style>
