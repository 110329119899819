<template>
  <ui-tabs class="orders__tabs" :class="getTabsClass">
    <template v-if="context === 'search'">
      <router-link :to="{ name: 'orders', params: { shopId }, query: { context: 'awaiting' } }">
        <ui-tabs-tab slot-scope="{ href, navigate }" :href="href" @click.native="navigate">
          <ui-icon
            type="back-arrow"
            size="14"
          />
        </ui-tabs-tab>
      </router-link>
      <ui-tabs-tab active>
        Search results ({{ orders.length }})
      </ui-tabs-tab>
    </template>
    <template v-else>
      <router-link :to="{ name: 'orders', params: { shopId }, query: { context: 'awaiting' } }">
        <ui-tabs-tab
          slot-scope="{ href, isExactActive, navigate }"
          :href="href"
          :active="isExactActive || (isInOrders && context === 'awaiting')"
          @click.native="navigate"
        >
          Awaiting <orders-badge :pending="pendingOrdersCount" />
        </ui-tabs-tab>
      </router-link>

      <router-link :to="{ name: 'orders', params: { shopId }, query: { context: 'accepted' } }">
        <ui-tabs-tab
          slot-scope="{ href, isExactActive, navigate }"
          :href="href"
          :active="isExactActive || (isInOrders && context === 'accepted')"
          @click.native="navigate"
        >
          Accepted <orders-badge :incomplete="incompleteOrdersCount" />
        </ui-tabs-tab>
      </router-link>

      <router-link :to="{ name: 'returns', params: { shopId }, query: { status: 'pending' } }">
        <ui-tabs-tab
          slot-scope="{ href, isActive, navigate }"
          :href="href"
          :active="isActive"
          @click.native="navigate"
        >
          Returns <orders-badge :incomplete="incompleteRmasCount" />
        </ui-tabs-tab>
      </router-link>

      <router-link :to="{ name: 'rma-requests', params: { shopId }, query: { status: 'pending' } }">
        <ui-tabs-tab
          slot-scope="{ href, isActive, navigate }"
          :href="href"
          :active="isActive"
          @click.native="navigate"
        >
          RMA Requests
        </ui-tabs-tab>
      </router-link>
    </template>

    <div slot="right">
      <slot name="right" />
    </div>
  </ui-tabs>
</template>

<script>
import {
  UiIcon,
  UiTabs,
  UiTabsTab,
} from '@ui';
import OrdersBadge from '@/modules/Orders/components/OrdersBadge.vue';

export default {
  name: 'ShopTabsView',
  components: {
    UiIcon,
    UiTabs,
    UiTabsTab,
    OrdersBadge,
  },
  props: {
    shopId: {
      type: String,
      default: null,
    },
    context: {
      type: String,
      default: 'awaiting',
    },
  },

  data: () => ({
    oldShopId: null,
  }),

  mounted() {
    this.oldShopId = this.$store.getters['shops/byId'](this.shopId).oldId;
  },

  computed: {
    orders() {
      return this.$store.state.orders.list;
    },

    pendingOrdersCount() {
      return this.$store.getters['statistics/countByShop'](this.oldShopId).pending;
    },

    incompleteOrdersCount() {
      return this.$store.getters['statistics/countByShop'](this.oldShopId).incomplete;
    },

    incompleteRmasCount() {
      return this.$store.getters['statistics/countByShop'](this.oldShopId).rmas;
    },

    getTabsClass() {
      return {
        'orders__tabs-search': this.context === 'search',
      };
    },

    isInOrders() {
      return this.$route.matched.some((match) => match.name === 'orders');
    },
  },

  watch: {
    shopId: {
      handler() {
        this.oldShopId = this.$store.getters['shops/byId'](this.shopId).oldId;
      },
    },
  },
};
</script>
