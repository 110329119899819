import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import ApiClass from '../Api/Api';
import { parseContextToLegacy, getContextId, getChannelUUID } from './Context.helpers';

export default class ContextApi extends ApiClass {
  getContext(contextId) {
    if (!contextId) {
      return Promise.reject();
    }

    return this.http.get(`/context/${contextId}`)
      .then(parseSuccessResponse)
      .then(async (response) => {
        const contextIdValue = getContextId(contextId, response);
        const channelIdValue = getChannelUUID(response);
        const { data } = await this.getSettings(contextIdValue, channelIdValue);
        return {
          ...response,
          data: parseContextToLegacy({ ...response.data.items[0], systemSettings: data?.locations[0]?.settings }),
        };
      })
      .catch(parseErrorResponse);
  }

  getSettings(contextId, channelId) {
    if (!contextId) {
      return Promise.reject();
    }

    return this.http.get(`/context/${contextId}/channels/${channelId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
