import Vue from 'vue';
import apiV2 from '@/apiV2/api';

import { getCountryCodeFromShopId } from '@/helpers';
import { countriesData } from '@/const';

const initialState = () => ({
  isLoading: false,
  isInitialized: false,
  list: [],
  countriesData,
});

export const types = {
  RESET: 'RESET',
  ADD_SHOP: 'ADD',
  FETCH_REQUEST: 'LIST/FETCH_REQUEST',
  FETCH_SUCCESS: 'LIST/FETCH_SUCCESS',
  FETCH_ERROR: 'LIST/FETCH_ERROR',
  FETCH_ONE_REQUEST: 'ONE/FETCH_REQUEST',
  FETCH_ONE_SUCCESS: 'ONE/FETCH_SUCCESS',
  INIT: 'INIT',
  INIT_END: 'INIT_END',
  REMOVE_SHOP: 'REMOVE',
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.FETCH_REQUEST](state) {
      state.isLoading = true;
    },

    [types.FETCH_SUCCESS](state) {
      state.isLoading = false;
    },

    [types.FETCH_ERROR](state) {
      state.isLoading = false;
    },

    [types.INIT](state, ids) {
      state.list = ids.map((id) => ({ id }));
    },

    [types.FETCH_ONE_REQUEST]() {},

    [types.FETCH_ONE_SUCCESS](state, payload) {
      const index = state.list.findIndex((element) => element.id === payload.id);

      if (index !== -1) {
        Vue.set(state.list, index, {
          ...state.list[index],
          oldId: payload.data.id,
          name: payload.data.name,
          shippingInformation: payload.data.shippingInformation,
          availabilityOptions: payload.data.availabilityOptions,
          countryCode: getCountryCodeFromShopId(payload.data.id),
        });
      }
    },

    [types.ADD_SHOP](state, data) {
      state.list.push(data);
    },

    [types.REMOVE_SHOP](state, data) {
      state.list = state.list.filter((item) => item.id !== data.id);
    },

    [types.INIT_END](state) {
      state.isInitialized = true;
    },
  },

  getters: {
    list: (state) => state.list.filter((item) => item.name),
    byId: (state) => (id) => state.list.find((item) => item.id === id),
    availabilityOptionsbyId: (state) => (id) => state.list.find((item) => item.id === id)?.availabilityOptions || {},
  },

  actions: {
    init({ commit }, ids) {
      commit(types.INIT, ids);
    },

    fetchAll({ commit, state, dispatch }) {
      commit(types.FETCH_REQUEST);
      return Promise.all(state.list.map((shop) => dispatch('fetchOne', shop.id)))
        .then(() => {
          dispatch('statistics/fetchAll', state.list.map((shop) => shop.id), { root: true });
          commit(types.FETCH_SUCCESS);
          commit(types.INIT_END);

          return true;
        })
        .catch(() => {
          commit(types.FETCH_ERROR);

          return false;
        });
    },

    fetchOne(context, id) {
      context.commit(types.FETCH_ONE_REQUEST, id);
      return apiV2(context).shopContext.getContext(id)
        .then((response) => {
          context.commit(types.FETCH_ONE_SUCCESS, { id, data: response.data });
          return response;
        })
        .catch(() => {});
    },

    addShop({ commit, dispatch }, id) {
      const additionalShops = JSON.parse(window.localStorage.getItem('additionalShops')) || [];
      if (additionalShops.indexOf(id) === -1) {
        commit(types.ADD_SHOP, { id });
        return dispatch('fetchOne', id)
          .then((response) => {
            commit(types.INIT_END);
            if (response) {
              dispatch('statistics/fetchAll', [id], { root: true });
              return id;
            }
            return false;
          });
      }
      return false;
    },

    removeShop({ commit }, id) {
      const additionalShops = JSON.parse(window.localStorage.getItem('additionalShops')) || [];
      const shopIndex = additionalShops.indexOf(id);

      if (shopIndex > -1) {
        additionalShops.splice(shopIndex, 1);
      }
      window.localStorage.setItem('additionalShops', JSON.stringify(additionalShops));

      commit(types.REMOVE_SHOP, { id });
    },
  },
};
